import React, { Component } from "react";

import "./DownloadAPP.css";

class DownloadAPP extends Component {
  render() {
    return (
    	<div className="downloadAPP">
        <div className="downloadAPPContainer">

        <div className="logo">
          <svg width="419px" height="419px" viewBox="0 0 419 419">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M283.239725,140.87713 C278.336398,130.912884 271.899413,120.982272 263.996696,111.571596 C259.767841,106.535812 255.319368,101.887182 250.722912,97.6488858 C268.265406,104.364734 282.8845,110.095432 283.464766,110.625554 C283.907413,111.02995 284.504913,122.983193 283.239726,140.87713 L283.239725,140.87713 Z M204.351614,80.1930783 C220.672408,89.0920772 236.818168,105.065589 248.92566,126.009479 C264.978829,153.778714 269.905523,183.319229 263.955244,204.390459 C257.512026,185.531816 246.602789,166.090601 231.515058,148.13281 C204.776557,116.307984 171.165106,96.5815707 141.741876,92.5244051 C164.973438,83.7119843 188.464562,75 189.507928,75 C190.015457,75 195.834553,77.061421 204.351608,80.193076 L204.351614,80.1930783 Z M182.806135,304 C116.154505,277.902998 99.3993341,190.342284 95.8481944,142.494649 C120.03612,152.515133 144.828041,175.029187 162.723825,205.98583 C182.619239,240.401491 189.20465,276.971768 182.806135,304 L182.806135,304 Z M95.1854249,130.666723 C94.7367662,118.566479 95.1992131,110.947023 95.5510894,110.625554 C96.0952349,110.128431 108.984823,105.058033 125.053643,98.8905648 C154.793039,101.627251 189.334702,127.31567 212.348887,167.12627 C238.001961,211.501699 241.278536,259.491727 222.882766,285.421459 C223.746251,255.44288 209.914087,218.720359 182.974805,186.649342 C156.835031,155.530135 124.234784,135.790766 95.1854249,130.666723 L95.1854249,130.666723 Z" id="Shape" fill="#75FFC0" fill-rule="nonzero"></path>
              </g>
          </svg>
        </div>
        <div className="details">
          <h3>آرگو وی‌پی‌ان</h3>
          <p>وی‌پی‌ان امن، رایگان و نامحدود برای عبور از سد فیلترینگ اینترنتی ایران با ویژگی‌هایی منحصر به فرد و مطمئن</p>
          <a href="https://play.google.com/store/apps/details?id=com.filtershekanha.argovpn" className="download" target="_blank" rel="noreferrer noopener">
            <span>دانلود از گوگل پلی‌ 
            <svg x="0px" y="0px" viewBox="0 0 512.029 512.029">
              <g fill="currentColor">
                <g>
                  <path d="M111.758,8.189C91.726-2.883,68.206-2.627,48.302,8.349l233.28,215.2l78.368-78.368L111.758,8.189z"/>
                </g>
                <g>
                  <path d="M24.974,30.365c-5.696,9.312-8.96,19.968-8.96,31.264v388.672c0,10.944,2.976,21.376,8.352,30.496l234.592-234.592
                    L24.974,30.365z"/>
                </g>
                <g>
                  <path d="M463.854,202.525l-74.752-41.248l-84,83.968l102.944,94.944l55.84-30.816c20.096-11.136,32.128-31.104,32.128-53.44
                    C495.982,233.597,483.982,213.629,463.854,202.525z"/>
                </g>
                <g>
                  <path d="M282.478,267.901L47.246,503.133c10.208,5.824,21.408,8.896,32.672,8.896c10.88,0,21.824-2.752,31.84-8.288
                    l266.784-147.232L282.478,267.901z"/>
                </g>
              </g>
            </svg>
            </span>
            <div className="fill"></div>
          </a>
        </div>

        </div>
      </div>
    );
  }
}

export default DownloadAPP;
